import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import { resetFilters } from '../../actions/news-filter-actions';

const ZeroResults = ({ dispatchResetFilters }) => {
  return (
    <div className="zero-results">
      <h4 className="heading-sm zero-results__title">0 risultati</h4>
      <ul className="zero-results__list">
        <li className="zero-results__list-item">Prova un formato, un argomento o un anno diverso.</li>
        <li className="zero-results__list-item">Cancella uno o più filtri</li>
      </ul>
      <Button
        handleClick={() => dispatchResetFilters()}
        label="Azzera i filtri"
        color="white"
        kind="small"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchResetFilters: () => dispatch(resetFilters())
  };
};

export default connect(null, mapDispatchToProps)(ZeroResults);
