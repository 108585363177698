import React from 'react';
import { graphql } from 'gatsby';
import MediaFiltersGlobal from '../components/MediaFiltersGlobal';
import MediaSearchResults from '../components/MediaSearchResults';
import Layout from '../containers/layout';
import HeaderNewsArchive from '../components/HeaderNewsArchive';

const NewsArchive = ({ data, pageContext }) => {
  const { title, label, seoMetaTags } = data.datoCmsNewsArchive;
  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Local News Archive"
      canonical={pageContext.canonical}
    >
      <MediaFiltersGlobal
        newsFormats={data.allDatoCmsFormatNews.nodes}
        categories={data.allDatoCmsCategoryNews.nodes}
        topics={data.allDatoCmsTopicNews.nodes}
        allNews={data.allDatoCmsNews.nodes}
      />
      <HeaderNewsArchive
        labelButton="Filtra per"
        title={title}
        subtitle={label}
      />
      <section className="archive">
        <div className="archive__wrapper">
          <MediaSearchResults />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query AllNewsQuery {
    datoCmsNewsArchive {
      id
      slug
      title
      label
      seoMetaTags {
        tags
      }
    }
    allDatoCmsNews(sort: {fields: publicationDate, order: DESC}) {
      nodes {
        id
        paragraph
        title
        slug
        publicationDate 
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        format {
          name
          slug
        }
        topic {
          slug
          name
          categoryNews {
            name
            slug
          }
        }
      } 
    }
    allDatoCmsFormatNews {
      nodes {
        name
        slug
      }
    }
    allDatoCmsCategoryNews {
      nodes {
        id
        slug
        name
      }
    }
    allDatoCmsTopicNews {
      nodes {
        parent {
          id
        }
        slug
        name
        categoryNews {
          slug
          name
        }
      }
    }
  }
`;

export default NewsArchive;
