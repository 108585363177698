import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  setAllNews,
  filterCollapse,
  setNewsFormats,
  setNewsCategories,
  setNewsTopics,
  setNewsYears
} from '../../actions/news-filter-actions';

/**
 * This component need to be called at the startup. It's used to connect datas
 * from graphql queries to the Redux Store, according to React's lifecycles
 */
const FilterGlobals = ({
  allNews,
  newsFormats,
  categories,
  topics,
  dispatchSetAllNews,
  dispatchSetNewsFormats,
  dispatchSetNewsCategories,
  dispatchSetNewsTopics,
  dispatchSetNewsYears
}) => {
  useEffect(() => {
    dispatchSetAllNews(allNews);
    dispatchSetNewsFormats(newsFormats);
    dispatchSetNewsCategories(categories);
    dispatchSetNewsTopics(topics);
    dispatchSetNewsYears();
  }, []);

  return (
    <>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetAllNews: (allNews) => dispatch(setAllNews(allNews)),
    dispatchFilterCollapse: () => dispatch(filterCollapse()),
    dispatchSetNewsFormats: (formats) => dispatch(setNewsFormats(formats)),
    dispatchSetNewsCategories: (categories) => dispatch(setNewsCategories(categories)),
    dispatchSetNewsTopics: (topics) => dispatch(setNewsTopics(topics)),
    dispatchSetNewsYears: () => dispatch(setNewsYears())
  };
};

export default connect(null, mapDispatchToProps)(FilterGlobals);
