import React, {
  useState, useLayoutEffect, useRef, useEffect
} from 'react';
import { MDCSelect } from '@material/select';
import SelectOption from '../SelectOption';
import { useCheckResize } from '../../hooks/match-media';

const Select = ({
  options, label, value, className, handleChange, variant
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [maxChars, setMaxChars] = useState({});
  const selectRef = useRef(null);
  const nativeSelectRef = useRef(null);

  useEffect(() => {
    const defaultSelectedIndex = options.map((option) => option.value).indexOf(value);
    const defaultSelected = options[defaultSelectedIndex];
    setSelectedOption(defaultSelected);
  }, [value, options]);

  useLayoutEffect(() => {
    new MDCSelect(selectRef.current);
  }, []);

  const handleSelectChange = (selectLabel, selectValue) => {
    const newSelectedOptions = {
      label: selectLabel,
      value: selectValue
    };
    setSelectedOption(newSelectedOptions);
    handleChange(selectValue, selectLabel);
  };

  const classes = `
    styled-select
    styled-select--fixed-label
    ${variant === 'white' ? 'styled-select--white' : ''}
    mdc-select
    mdc-select--filled
    demo-width-class
    ${className || ''}
  `.replace(/\n\s+/g, ' ');

  const handleNativeSelectChange = (e) => {
    const { selectedIndex, value } = e.target;
    const selectLabel = e.target[selectedIndex].getAttribute('data-label');
    handleSelectChange(selectLabel, value);
  };

  useCheckResize(() => setMaxChars(selectRef.current.clientWidth / 9));

  const handleDisplayValue = () => {
    if (selectRef.current) {
      if (selectedOption && selectedOption.value !== '') {
        if (selectedOption.label && selectedOption.label.length >= maxChars) {
          return `${selectedOption.label.toString().substring(0, maxChars - 3)}...`;
        }
        return selectedOption.label;
      }
      return label;
    }
  };

  const displayValue = handleDisplayValue();

  return (
    <div ref={selectRef} className={classes}>
      <select value={value} onChange={(e) => handleNativeSelectChange(e)} className="hidden-select" ref={nativeSelectRef}>
        {
            options.map((option) => {
              const { label, value } = option;
              return (
                <option key={value} value={value} data-label={label}>{label}</option>
              );
            })
          }
      </select>
      <div className="mdc-select__anchor">
        <span className="mdc-select__ripple" />
        <span className="mdc-select__selected-text" />
        <span className="mdc-select__dropdown-icon">
          <svg
            className="mdc-select__dropdown-icon-graphic"
            viewBox="7 10 10 5"
          />
        </span>
        <div className="mdc-floating-label label-small">{displayValue}</div>
        <span className="mdc-line-ripple" />
      </div>
      <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
        <ul className="mdc-list">
          {
            options.map((option) => {
              const { label, value } = option;
              const isSelected = selectedOption ? selectedOption.value === value : value === '';
              return (
                <SelectOption
                  key={value}
                  label={label}
                  value={value}
                  isSelected={isSelected}
                  onClick={(selectLabel, selectValue) => (
                    handleSelectChange(selectLabel, selectValue)
                  )}
                />
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};

export default Select;

Select.defaultProps = {
  options: [
    {
      label: 'Fruit',
      value: 'fruit'
    },
    {
      label: 'Vegetables',
      value: 'vegetables'
    }
  ],
  label: 'Pick a Food Group'
};
