import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  filterNewsByFormat,
  filterNewsByCategory,
  filterNewsByTopic,
  filterNewsByYear,
  setCurrentTopics,
  filterCollapse
} from '../../actions/news-filter-actions';
import SelectFilter from '../SelectFilter';

const FilterList = ({
  dispatchFilterNewsByFormat,
  dispatchFilterNewsByCategory,
  dispatchFilterNewsByTopic,
  dispatchFilterNewsByYear,
  dispatchFilterCollapse,
  dispatchSetCurrentTopics,
  handleSubmit,
  optionsFormats,
  optionsCategories,
  optionsTopics,
  optionsYears,
  currentTopics,
  filters,
  variant,
  selectedFormat,
  selectedCategory,
  selectedTopic,
  selectedYear
}) => {
  useEffect(() => {
    /** Selecting the related topics when category changes */
    if (optionsTopics && selectedCategory) {
      dispatchSetCurrentTopics(optionsTopics, selectedCategory.value);
    }
  }, [selectedCategory, optionsTopics]);

  useEffect(() => {
    if (!handleSubmit) {
      /** Handling filters on change */
      dispatchFilterCollapse();
    }
  }, [filters]);

  const handleFormatChange = (value, label) => {
    dispatchFilterNewsByFormat({ label, value });
  };

  const handleCategoryChange = (value, label) => {
    dispatchFilterNewsByCategory({ label, value });
    dispatchFilterNewsByTopic({ label: '', value: '' });
  };

  const handleTopicChange = (value, label) => {
    dispatchFilterNewsByTopic({ label, value });
  };

  const handleYearChange = (value, label) => {
    dispatchFilterNewsByYear({ label, value });
  };

  return (
    <div className="filters-list">
      <SelectFilter
        handleChange={handleFormatChange}
        options={[{ label: 'Tutti', value: '' }, ...optionsFormats]}
        value={selectedFormat.value}
        label="Formato"
        variant={variant}
        className="filters-list__select"
      />
      <SelectFilter
        handleChange={handleCategoryChange}
        value={selectedCategory.value}
        options={[{ label: 'Tutti', value: '' }, ...optionsCategories]}
        label="Area tematica"
        variant={variant}
        className="filters-list__select"
      />
      <SelectFilter
        handleChange={handleTopicChange}
        value={selectedTopic.value}
        options={[{ label: 'Tutti', value: '' }, ...currentTopics]}
        label="Topic"
        variant={variant}
        className="filters-list__select"
      />
      <SelectFilter
        handleChange={handleYearChange}
        options={optionsYears}
        value={selectedYear.value}
        label="Anno"
        variant={variant}
        className="filters-list__select"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.newsFilter,
    filters: state.newsFilter.filters,
    optionsFormats: state.newsFilter.options.formats,
    optionsCategories: state.newsFilter.options.categories,
    optionsTopics: state.newsFilter.options.topics,
    optionsYears: state.newsFilter.options.years,
    currentTopics: state.newsFilter.options.currentTopics,
    selectedFormat: state.newsFilter.filters.format,
    selectedCategory: state.newsFilter.filters.category,
    selectedTopic: state.newsFilter.filters.topic,
    selectedYear: state.newsFilter.filters.year
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchFilterNewsByFormat: (format) => dispatch(filterNewsByFormat(format)),
    dispatchFilterNewsByCategory: (category) => dispatch(filterNewsByCategory(category)),
    dispatchFilterNewsByTopic: (topic) => dispatch(filterNewsByTopic(topic)),
    dispatchFilterNewsByYear: (year) => dispatch(filterNewsByYear(year)),
    dispatchFilterCollapse: () => dispatch(filterCollapse()),
    dispatchSetCurrentTopics: (topics, selectedTopic) => {
      dispatch(setCurrentTopics(topics, selectedTopic));
    }
  };
};

FilterList.defaultProps = {
  variant: 'white'
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);
