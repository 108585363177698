import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Pagination from '../Pagination';
import { useResetPage, useChangeDisplayPagesOnResize } from '../../hooks/archive-hooks';
import ListCardsImage from '../ListCardsImage';
import ZeroResults from '../ZeroResults';

const MediaSearchResults = ({ archiveNews, filters }) => {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayPages, setDisplayPages] = useState(4);

  const itemsPerPage = 12;

  const handleChangePage = (pageResults, page) => {
    setResults(pageResults);
    setCurrentPage(page);
  };

  useResetPage(setCurrentPage, filters);
  useChangeDisplayPagesOnResize(setDisplayPages);

  useEffect(() => {
    if (archiveNews.length <= itemsPerPage) {
      setResults(archiveNews);
    }
  }, [archiveNews]);

  return (
    <>
      {
        results && (
          <section className="media-results">
            {
              results.length > 0 ? (
                <ListCardsImage cards={results} />
              ) : (
                <ZeroResults />
              )
            }
          </section>
        )
      }
      {
        archiveNews
        && archiveNews.length > itemsPerPage
        && (
        <Pagination
          items={archiveNews}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          displayPages={displayPages}
          handleChangePage={handleChangePage}
        />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    archiveNews: state.newsFilter.filteredNews,
    filters: state.newsFilter.filters
  };
};

export default connect(mapStateToProps, null)(MediaSearchResults);
