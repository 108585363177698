import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import Icon from '../Icons';
import ReduxContainer from '../../containers/redux-container';
import SelectFilter from '../SelectFilter';
import {
  filterNewsByFormat,
  filterNewsByCategory,
  filterNewsByTopic,
  filterNewsByYear,
  setCurrentTopics,
  filterCollapse
} from '../../actions/news-filter-actions';

const FiltersModal = ({
  dispatchFilterNewsByFormat,
  dispatchFilterNewsByCategory,
  dispatchFilterNewsByTopic,
  dispatchFilterNewsByYear,
  dispatchFilterCollapse,
  dispatchSetCurrentTopics,
  optionsFormats,
  optionsCategories,
  optionsTopics,
  optionsYears,
  currentTopics,
  filters,
  handleClose
}) => {
  const [selectedFormats, setSelectedFormats] = useState(filters.format);
  const [selectedCategory, setSelectedCategory] = useState(filters.category);
  const [selectedTopic, setSelectedTopic] = useState(filters.topic);
  const [selectedYear, setSelectedYear] = useState(filters.year);
  const [isButtonApplyDisabled, setIsButtonApplyDisabled] = useState(true);
  const [isButtonCancelDisabled, setIsButtonCancelDisabled] = useState(true);

  const handleFormatChange = (value, label) => {
    setSelectedFormats({ label, value });
    setIsButtonApplyDisabled(false);
  };

  const handleCategoryChange = (value, label) => {
    setSelectedCategory({ label, value });
    setSelectedTopic({ label: '', value: '' });
    if (value) {
      setIsButtonApplyDisabled(false);
    }
  };

  const handleTopicChange = (value, label) => {
    setSelectedTopic({ label, value });
    setIsButtonApplyDisabled(false);
  };

  const handleYearChange = (value, label) => {
    setSelectedYear({ label, value });
    setIsButtonApplyDisabled(false);
  };

  const handleClosePopup = () => {
    handleClose(false);
  };

  const dispatchAll = () => {
    dispatchFilterNewsByFormat(selectedFormats);
    dispatchFilterNewsByCategory(selectedCategory);
    dispatchFilterNewsByTopic(selectedTopic);
    dispatchFilterNewsByYear(selectedYear);
  };

  const handleSubmit = () => {
    dispatchAll();
    handleClose(false);
  };

  const handleCancel = () => {
    const nullValue = { label: 'Tutti', value: '' };
    setSelectedFormats(nullValue);
    setSelectedCategory(nullValue);
    setSelectedTopic(nullValue);
    setSelectedYear(nullValue);
    dispatchFilterNewsByFormat(nullValue);
    dispatchFilterNewsByCategory(nullValue);
    dispatchFilterNewsByTopic(nullValue);
    dispatchFilterNewsByYear(nullValue);
  };

  useEffect(() => {
    /** Handling filters on change */
    dispatchFilterCollapse();
  }, [filters]);

  useEffect(() => {
    /** Selecting the related topics when category changes */
    if (optionsTopics && selectedCategory) {
      dispatchSetCurrentTopics(optionsTopics, selectedCategory.value);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedFormats.value !== '' || selectedCategory.value !== '' || selectedTopic.value !== '' || selectedYear.value !== '') {
      setIsButtonCancelDisabled(false);
    } else {
      setIsButtonCancelDisabled(true);
    }
  }, [selectedFormats, selectedCategory, selectedTopic, selectedYear]);
  return (
    <ReduxContainer>
      <header className="popup__header">
        <span className="label text-color-brand">Filtra per</span>
        <button type="button" className="popup__close-popup" onClick={() => handleClosePopup()}>
          <Icon className="icon--24 popup__close-icon" path="close" />
        </button>
      </header>
      <div className="popup__content">
        <div className="filters-list">
          <SelectFilter
            handleChange={handleFormatChange}
            options={[{ label: 'Tutti', value: '' }, ...optionsFormats]}
            value={selectedFormats.value}
            label="Formato"
            variant="black"
          />
          <SelectFilter
            handleChange={handleCategoryChange}
            value={selectedCategory.value}
            options={[{ label: 'Tutti', value: '' }, ...optionsCategories]}
            label="Area tematica"
            variant="black"
          />
          <SelectFilter
            handleChange={handleTopicChange}
            value={selectedTopic.value}
            options={[{ label: 'Tutti', value: '' }, ...currentTopics]}
            label="Topic"
            variant="black"
          />
          <SelectFilter
            handleChange={handleYearChange}
            options={optionsYears}
            value={selectedYear.value}
            label="Anno"
            variant="black"
          />
        </div>
      </div>
      <footer className="popup__footer">
        <Button
          handleClick={() => handleCancel()}
          kind="small"
          label="Cancella"
          color="white"
          className="popup__btn"
          disabled={isButtonCancelDisabled}
        />
        <Button
          handleClick={() => handleSubmit()}
          kind="small"
          label="Applica"
          color="brand"
          className="popup__btn"
          disabled={isButtonApplyDisabled}
        />
      </footer>
    </ReduxContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.newsFilter,
    filters: state.newsFilter.filters,
    optionsFormats: state.newsFilter.options.formats,
    optionsCategories: state.newsFilter.options.categories,
    optionsTopics: state.newsFilter.options.topics,
    optionsYears: state.newsFilter.options.years,
    currentTopics: state.newsFilter.options.currentTopics
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchFilterNewsByFormat: (format) => dispatch(filterNewsByFormat(format)),
    dispatchFilterNewsByCategory: (category) => dispatch(filterNewsByCategory(category)),
    dispatchFilterNewsByTopic: (topic) => dispatch(filterNewsByTopic(topic)),
    dispatchFilterNewsByYear: (year) => dispatch(filterNewsByYear(year)),
    dispatchFilterCollapse: () => dispatch(filterCollapse()),
    dispatchSetCurrentTopics: (topics, selectedTopic) => {
      dispatch(setCurrentTopics(topics, selectedTopic));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersModal);
