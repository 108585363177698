import React, { useState } from 'react';
import Container from '../Container';
import MediaFilterBar from '../MediaFiltersBar';
import MediaFiltersPopup from '../MediaFiltersPopup';
import Icon from '../Icons';

const HeaderNewsArchive = ({ title, subtitle, labelButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container className="header-page header-page--only-text header-page--news-archive">
      <div className="header-page__wrapper">
        <div className="header-page__container">
          <span className="header-page__subtitle label-small">{subtitle}</span>
          <h2 className="header-page__title heading-xl">{title}</h2>
        </div>
        <button type="button" className="filters-btn" onClick={() => setIsOpen(true)}>
          <span className="filters-btn__label label-small">{labelButton}</span>
          <Icon className="widget-social__icon icon--24" path="tune" />
        </button>
        <MediaFilterBar />
      </div>
      {
        isOpen && (
          <MediaFiltersPopup handleOpen={(value) => setIsOpen(value)} />
        )
      }
    </Container>
  );
};

export default HeaderNewsArchive;
