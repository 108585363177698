import React, { useEffect, useState } from 'react';
import ReduxContainer from '../../containers/redux-container';
import FiltersModal from '../FiltersModal';

const Popup = ({ handleOpen }) => {
  const handleClose = () => {
    handleOpen(false);
  };

  const [opacity, setOpacity] = useState(0);
  const [translateY, setTranslateY] = useState('100%');
  useEffect(() => {
    setOpacity(1);
    setTranslateY('0');
  }, []);

  return (
    <ReduxContainer>
      <div
        className="popup"
        style={{
          opacity,
          transform: `translateY(${translateY})`,
          transition: 'opacity 0.3s ease-in, transform 0.3s ease-in'
        }}
      >
        <FiltersModal handleClose={handleClose} />
      </div>
    </ReduxContainer>
  );
};

export default Popup;
